import { Box, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import DealerCards from "../sections/dealerdetails/DealerCards";
import totaldealers from "../assets/totaldealers.svg";
import agreements from "../assets/agreements.svg";
import revenue from "../assets/revenue.svg";
import onboarding from "../assets/onboarding.svg";
import LatestOnboardingCard from "../sections/overview/latestOnboardingCard";


import { getAdminDashbaordCardData } from "../services/adminDashboardServices";

import { useContext } from "react";
import { AuthContext } from "../AuthContext";
import AgreementsClaimsTabs from "../components/AgreementsClaimsTabs";

const Overview = () => {
  const { setDealer } = useContext(AuthContext);
  const [adminCardsData, setAdminCardsData] = useState(null);

  useEffect(() => {
    setDealer(null);
    localStorage.removeItem("dealer");
  }, []);
  const getAdminCardsData = () => {
    getAdminDashbaordCardData()
      .then(
        (res) => {
          console.log("Admin Cards Data Response", res);
          const data = res.data.body;
          console.log("Admin Cards Data", data);
          const statusCode = res.status;
          if (statusCode === 200 && data.length > 0) {
            setAdminCardsData(data[0]);
          }
        },
        (err) => {
          console.log("error loading data", err);
        }
      )
      .catch((err) => {
        console.error("Error calling Admin Dashboard cards ", err);
      });
  };

  useEffect(() => {
    getAdminCardsData();
  }, []);

  return (
    <Box component="main">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          {/* <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="h6" component="h6">
              Hello {user.name}.
            </Typography>
          </Grid> */}
          <Grid item xs={12} sm={6} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={2}>
                <DealerCards
                  value={
                    adminCardsData?.totalDealers
                      ? adminCardsData?.totalDealers
                      : 0
                  }
                  imgsrc={totaldealers}
                  titletxt={"Total Dealers"}
                  sx={{
                    maxWidth: "100%",
                    height: "90%",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <DealerCards
                  value={
                    adminCardsData?.onboardedDealers
                      ? adminCardsData?.onboardedDealers
                      : 0
                  }
                  imgsrc={onboarding}
                  titletxt={"Onboarded Dealers"}
                  sx={{
                    maxWidth: "100%",
                    height: "90%",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <DealerCards
                  value={
                    adminCardsData?.agreementsCreated
                      ? adminCardsData?.agreementsCreated
                      : 0
                  }
                  imgsrc={agreements}
                  titletxt={"Agreements Created"}
                  sx={{
                    maxWidth: "100%",
                    height: "90%",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <DealerCards
                  value={
                    adminCardsData?.totalClaims
                      ? `${adminCardsData?.totalClaims}`
                      : `0`
                  }
                  titleFontSize="25px"
                  imgsrc={revenue}
                  titletxt={"Claims Created"}
                  sx={{
                    maxWidth: "100%",
                    height: "90%",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <LatestOnboardingCard
                  sx={{ maxWidth: "100%", borderRadius: "10px" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <AgreementsClaimsTabs displayType="admin" showDealer={true} />
              </Grid>
              {/* <Grid item xs={12} sm={12} lg={12}>
                <TransmissionDetailsList
                  showDealer={true}
                  displayType="admin"
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Overview;
