import "./App.css";
import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import HomeLayout from "./layouts/HomeLayout";
import RootLayout from "./layouts/RootLayout";
import Overview from "./pages/Overview";
import UsersList from "./pages/UsersList";
import { Settings } from "@mui/icons-material";
import OnboardingLayout from "./layouts/OnboardingLayout";
import ClientName from "./pages/onboarding/ClientName";
import ClientLocation from "./pages/onboarding/ClientLocation";
import ClientCustom from "./pages/onboarding/ClientCustom";
import ClientId from "./pages/onboarding/ClientId";
import CLientConnect from "./pages/onboarding/CLientConnect";
import Onboarding from "./pages/Onboarding";
import { CssBaseline, ThemeProvider } from "@mui/material";
import appTheme from "./themes/theme";
import { AuthProvider } from "./AuthContext";
import RouteGuard from "./RouteGuard";
import DealerDetails from "./pages/DealerDetails";
import ChangePassword from "./pages/ChangePassword";
import VerifyUser from "./pages/VerifyUser";
import ToDoHome from "./pages/ToDoHome";
import AgreementsSyncHome from "./pages/AgreementsSyncHome";
import TransactionLogs from "./pages/TransactionLogs";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route exact path="/" element={<RootLayout />}>
      <Route index element={<SignIn />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="signup" element={<Register />} />
      <Route path="forgotpassword" element={<VerifyUser />} />
      <Route path="changepassword/:id" element={<ChangePassword />} />
      <Route element={<RouteGuard />}>
        <Route path="home" element={<HomeLayout />}>
          <Route index element={<Overview />} />
          <Route path="overview" element={<Overview />} />
          <Route path="dealerdetails" element={<DealerDetails />} />
          <Route path="dealerdetails/:id" element={<DealerDetails />} />
          <Route path="todohome" element={<ToDoHome />} />
          <Route path="todohome/:id" element={<ToDoHome />} />
          <Route path="onboardinghome" element={<Onboarding />} />
          <Route path="agreementssynchome" element={<AgreementsSyncHome />} />
          <Route path="onboarding" element={<OnboardingLayout />}>
            <Route index element={<ClientName />} />
            <Route path="clientname" element={<ClientName />} />
            <Route path="clientlocation" element={<ClientLocation />} />
            <Route path="clientcustome" element={<ClientCustom />} />
            <Route path="clientid" element={<ClientId />} />
            <Route path="clientconnect" element={<CLientConnect />} />
          </Route>
          <Route path="userslist" element={<UsersList />} />
          <Route path="settings" element={<Settings />} />
          <Route path="transactionlog" element={<TransactionLogs />} />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
